import React from 'react';
import Image from 'next/image';

export const InsCard = ({ headerText, bodyText, buttonLink }:any ) => {
    return (


      <div className="py-12 md:py-24 my-10 md:my-20 bg-slate-50">
                  <div className="container mx-auto px-4">
    <div className="flex flex-wrap -mx-4 items-center">
      <div className="w-full md:w-6/12 px-4 mb-20 lg:mb-0">
        <div className="md:max-w-xl mx-auto lg:mx-0">
          <h2 className="font-heading leading-tight tracking-tighter text-[2.5rem] sm:text-[2.81rem] md:text-5xl lg:text-[3.5rem] xl:text-6xl ">{headerText || `We accept most insurances including Medicare and Medicaid`}</h2>
          <p className="max-w-full text-slate-600 mb-10">{bodyText || `We understand the importance of accessibility to quality healthcare. That's why we accept most insurance plans, including Medicare and Medicaid. Contact us today to schedule your appointment and take the first step towards better heart health.`}</p>
          <a className="group inline-flex py-5 px-8 items-center justify-center leading-none font-medium text-black hover:text-white border border-black rounded-full hover:bg-blue-800 transition duration-200"href="tel:281-955-9158">
            <span className="mr-2">{buttonLink ||`Schedule an Appointment`}</span>
            <span className="group-hover:rotate-45 transform transition duration-100">
              <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1L1 9" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9 8.33571V1H1.66429" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>
          </a>
        </div>
      </div>
      <div className="w-full md:w-6/12 px-4">
        <div className="hidden xs:block max-w-xl mx-auto lg:mr-0">
          <div className="flex justify-center">
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-2 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/bcbs-ins-icons.png" alt= "Blue Cross Blue Shield"
                width="86"  
                height="50"/>
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-2 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/aetna-ins-icons.png" alt="Aetna" 
                  width="114"
                  height="26"
                />
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-2 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/cigna-ins-icons.png" alt="Cigna" width="90"
                height="44"/>
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-2 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/humana-ins-icons.png" alt="Humana" width="114"
                height="28"/>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-2 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/medicaid-ins-icons.png" alt="Medicaid" width="114"
                height="28" />
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-2 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/medicare-ins-icons.png" alt="Medicare" width="114"
                height="24"/>
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-2 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/united-ins-icons.png" alt="United Healthcare" width="106" height="28" />
              </div>
            </div>
            {/* <div className="p-2">
              <div className="inline-flex h-30 w-30 p-2 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/bcbs-ins-icons0.png" alt="bcbs" />
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-2 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/bcbs-ins-icons.png" alt="bcbs" />
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-2 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/medicare-ins-icons.png" alt="Medicare" width="43"
                height="45"/>
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-2 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/bcbs-ins-icons.png" alt="bcbs" />
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-2 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/bcbs-ins-icons.png" alt="bcbs" />
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-2 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/aetna-ins-icons.png" alt="Aetna" />
              </div>
            </div> */}
          </div>
        </div>
        <div className="xs:hidden">
          <div className="flex justify-center">
            <div className="p-2">
              <div className="inline-flex h-35  w-35 p-3 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/bcbs-ins-icons.png" alt="bcbs"width="86"
                height="50"/>
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-3 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/aetna-ins-icons.png" alt="Aetna" width="114" height="26"/>
              </div>
            </div>
            <div className="p-2">
              {/* <div className="inline-flex h-16 w-16 p-3 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/cigna-ins-icons.png" alt="Cigna" width="45" height="22" />
              </div> */}
            </div>
          </div>
          <div className="flex justify-center">
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-3 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/humana-ins-icons.png" alt="Humana" width="114" height="28" />
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-3 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/medicaid-ins-icons.png" alt="Medicaid" width={114} height={24}/>
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-3 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/united-ins-icons.png" alt="United" width={106} height={28} />
              </div>
            </div>
            {/* <div className="p-2">
              <div className="inline-flex h-16 w-16 p-3 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/bcbs-ins-icons.png" alt="bcbs" />
              </div>
            </div> */}
          </div>
          <div className="flex justify-center">
            {/* <div className="p-2">
              <div className="inline-flex h-16 w-16 p-3 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/bcbs-ins-icons0.png" alt="bcbs" />
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-16 w-16 p-3 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/bcbs-ins-icons.png" alt="bcbs" />
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-16 w-16 p-3 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/bcbs-ins-icons.png" alt="bcbs" />
              </div>
            </div> */}
            {/* <div className="p-2">
              <div className="inline-flex h-16 w-16 p-3 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/medicaid-ins-icons.png" alt="Medicaid" width={57} height={14} />
              </div>
            </div> */}
          </div>
          <div className="flex justify-center">
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-3 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/cigna-ins-icons.png" alt="Cigna" width={90} height={44}/>
              </div>
            </div>
            <div className="p-2">
              <div className="inline-flex h-30 w-30 p-3 items-center justify-center bg-white rounded-3xl">
                <Image className="block" src="/insurance/medicare-ins-icons.png"  width={114} height={24} alt="Medicare" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    );
}
